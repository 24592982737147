// ./src/components/ChatInput.tsx
import React, { useState, useRef, useEffect } from "react";
import { IconButton, TextareaAutosize } from "@material-ui/core";
import { AddCircle, AttachFile, Mic, Send, Stop } from "@material-ui/icons";
import WebSocketClient from "./WebSocketClient";
import { useRecoilState } from "recoil";
import { jwtTokenState } from "../recoil/atoms";
import botrunUseAudioRecorder from "./BotrunUseAudioRecorder";

interface ChatInputProps {
  onSendMessage: (message: string, isUserInput: boolean) => void;
  onUploadFiles: (files: FileList) => void;
  messages: Array<{ message: string; isUserMessage: boolean }>;
  setMessages: React.Dispatch<
    React.SetStateAction<Array<{ message: string; isUserMessage: boolean }>>
  >;
  params: string | null;
  webSocketClient: WebSocketClient | null;
  isAssistantSpeaking: boolean;
  setIsAssistantSpeaking: React.Dispatch<React.SetStateAction<boolean>>;
  externalInput: string | null;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  onUploadFiles,
  messages,
  setMessages,
  params,
  webSocketClient,
  isAssistantSpeaking: isAssistantAnswering,
  setIsAssistantSpeaking,
  externalInput,
}) => {
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { startRecording, stopRecording, fileData } = botrunUseAudioRecorder();
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [inputValue]);

  useEffect(() => {
    if (externalInput) {
      console.log("ChatInput.tsx, useEffect, externalInput", externalInput);
      onSendMessage(externalInput, true);
      setIsAssistantSpeaking(true);
      if (webSocketClient) {
        webSocketClient.disconnect();
        webSocketClient.connect();
        webSocketClient.sendMessage({
          user_input: externalInput,
          jwt_token: jwtToken,
        });
      }
      setInputValue("");
    }
  }, [externalInput]);

  const handleReset = () => {
    // 1. Send the message "reset" to the server.
    onSendMessage("reset", true);
    if (webSocketClient) {
      webSocketClient.disconnect();
      webSocketClient.connect();
      webSocketClient.sendMessage({
        user_input: "reset",
        jwt_token: jwtToken,
      });
    }

    // 2. Clear the messages on the screen.
    setMessages([]);
  };

  const handleSend = () => {
    if (inputValue) {
      onSendMessage(inputValue, true);
      setIsAssistantSpeaking(true);
      if (webSocketClient) {
        webSocketClient.disconnect();
        webSocketClient.connect();
        webSocketClient.sendMessage({
          user_input: inputValue,
          jwt_token: jwtToken,
        });
      }
      setInputValue("");
    }
  };

  const handleStop = () => {
    if (isAssistantAnswering) {
      setIsAssistantSpeaking(false);
      if (webSocketClient) {
        webSocketClient.disconnect();
      }
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onUploadFiles(e.target.files);
      e.target.value = "";
    }
  };

  const handleRecord = () => {
    setIsRecording(!isRecording);
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    if (fileData) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(fileData);
      onUploadFiles(dataTransfer.files);
    }
  }, [fileData]);

  return (
    <div style={{ display: "flex", alignItems: "center", padding: 8 }}>
      <IconButton size="medium" onClick={handleReset}>
        <AddCircle />
      </IconButton>
      <IconButton size="medium" onClick={() => fileInputRef.current?.click()}>
        <AttachFile />
      </IconButton>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <TextareaAutosize
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        minRows={1}
        maxRows={8}
        placeholder="Type your message..."
        style={{
          flexGrow: 1,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 15,
          paddingLeft: 15,
          paddingRight: 15,
          resize: "none",
          overflow: "auto",
          fontSize: "1.1rem",
          lineHeight: "1.5rem",
          color: "white",
          maxHeight: "8rem",
          overflowY: "auto",
        }}
        ref={textAreaRef}
      />
      <IconButton size="medium" onClick={handleRecord}>
        {isRecording ? <Stop /> : <Mic />}
      </IconButton>
      <IconButton
        size="medium"
        onClick={isAssistantAnswering ? handleStop : handleSend}
        style={{ color: isAssistantAnswering ? "red" : "inherit" }} // 根據isAssistantAnswering的狀態設定顏色
      >
        {isAssistantAnswering ? <Stop /> : <Send />}
      </IconButton>
    </div>
  );
};

export default ChatInput;


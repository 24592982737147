import React, { useState, FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../services/apiService";
import { useRecoilState } from "recoil";
import { jwtTokenState, defaultBotrunState } from "../recoil/atoms";

interface LoginProps {
  onLogin: () => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "400px",
  },
  field: {
    margin: "10px 0",
  },
  button: {
    margin: "20px 0",
  },
  feature: {
    margin: "20px 0",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  const [default_botrun, setDefaultBotrun] = useRecoilState(defaultBotrunState);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const data = await login(username, password, default_botrun);
      if (data.is_success) {
        setJwtToken(data.access_token);
        onLogin();
      } else {
        setError("Username or password is incorrect.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.container}>
      <h2>BotRun.ai</h2>
      <div className={classes.feature}>
        <h3>Features:</h3>
        <ul>
          <li>
            <strong>🤖 Domain Bot:</strong> Domain experts can use natural
            language to create and share new bots.
          </li>
          <li>
            <strong>🔠 Dynamic Execution:</strong> With support for 50 different
            programming languages, our AI dynamically generates and executes
            source code.
          </li>
          <li>
            <strong>🔒 Confidential Data:</strong> AI conversation results are
            automatically compiled into a Docker image, data is processed within a secure and closed network.
          </li>{" "}
        </ul>
      </div>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          className={classes.field}
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          className={classes.field}
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <div>{error}</div>}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
        >
          Login
        </Button>
      </form>
    </div>
  );
};

export default Login;
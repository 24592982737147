import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#FFFFFF',
  },
});

const NoAuth = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>很抱歉，您沒有使用此服務的權限。</h2>
    </div>
  );
};

export default NoAuth;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { InitOptions } from 'i18next';

const i18nConfig: InitOptions = {
  lng: 'en', // 預設語言設為英文
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  backend: {
    loadPath: '/locales/{{lng}}.json',
  },
  supportedLngs: ['en', 'zh-Hant'],
  // You can add other configuration options here, like detection, caching, etc.
};

i18n
  .use(Backend) // enables loading translations from a server
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init(i18nConfig);

export default i18n;

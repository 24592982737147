// ./src/components/BeginClientPost.ts
import axios from "axios";
import qs from "qs"; // 這是用於轉換數據格式的一個包

export const beginClientPost = async (message: string) => {
  let response = "";

  const getContents = /@begin\s*client_post\("([^"]+)"\)\s*([\S\s]*?)\s*@end/g;

  const match = getContents.exec(message);

  if (match !== null) {
    const url = match[1];
    console.log(`BeginClientPost.ts,URL:`, url);

    let contents = match[2].trim();
    let data: any = null;

    try {
      data = JSON.parse(contents);
    } catch (e) {
      console.error(`BeginClientPost.ts,Parsing JSON failed`, e);
      return response;
    }

    // Serialize any object or array properties to JSON
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "object" || Array.isArray(data[key])) {
        data[key] = JSON.stringify(data[key]);
      }
    });

    // 轉換數據格式
    const formattedData = qs.stringify(data);
    console.log(`Formatted data:`, formattedData);

    try {
      console.log(
        `BeginClientPost.ts,Posting data to ${url}, data:`,
        formattedData
      );
      const result = await axios.post(url, formattedData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      response = result.data;
    } catch (error) {
      console.error(`BeginClientPost.ts,Failed to post data to ${url}`, error);
      console.log(`BeginClientPost.ts,Error response:`, response);
    }
  }

  return response;
};

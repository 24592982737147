// ./src/components/ChatWindow.tsx
import React, { useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';

interface ChatWindowProps {
  messages: Array<{ message: string; isUserMessage: boolean }>;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ messages }) => {
  const chatWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={chatWindowRef}
      style={{ flexGrow: 1, overflowY: 'auto', paddingBottom: 8 }}
    >
      {messages.map((messageObj, index) => (
        <ChatMessage
          key={index}
          message={messageObj.message}
          isUserMessage={messageObj.isUserMessage}
        />
      ))}
    </div>
  );
};

export default ChatWindow;
import React, { useState, FormEvent, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../services/apiService";
import { useRecoilState } from "recoil";
import { jwtTokenState, defaultBotrunState } from "../recoil/atoms";
import { v4 as uuidv4 } from "uuid";

import { useLocation, useNavigate } from 'react-router-dom'; // 如果你正在使用 react-router
import { sha256 } from 'js-sha256'; //安張套件 yarn add js-sha256

interface LoginProps {
  onLogin: () => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#FFFFFF',
  },
});

const LoginWp: React.FC<LoginProps> = ({ onLogin }) => {
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  const [default_botrun, setDefaultBotrun] = useRecoilState(defaultBotrunState);
  const [error, setError] = useState("");
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get('user') || ''; // 參數
  const tokenFromWordpress = queryParams.get('btoken'); // 參數
  const timestamp = queryParams.get('t') || '0'; // 參數
  const secret = process.env.REACT_APP_HD_WP_WORD!; 

  useEffect(() => {
    const validateConditions = () => {
      const userEmailFromWordpress = user || "";
      const generatedToken = sha256(`${user}${timestamp}${secret}`).toString();
      const currentTime = Math.floor(Date.now() / 1000);
      const timestampNum = parseInt(timestamp, 10); // 轉換為數字
      const timeDifference = Math.abs(currentTime - timestampNum); // 絕對值差異
      const valid = parseInt(process.env.REACT_APP_HD_WORD_VALID_TIME!, 10); //轉換為數字

      return (generatedToken === tokenFromWordpress && timeDifference < valid && timeDifference >= 0);
    };

    const autoLogin = async () => {
      const Username = user;
      const word = process.env.REACT_APP_HD_WORD!;

      try {
        const data = await login(Username, word, default_botrun);
        if (data.is_success) {
          setJwtToken(data.access_token);
          localStorage.setItem('jwtToken', data.access_token);
          onLogin();
        } else {
          setError("Username or password is incorrect.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (validateConditions()) {
      autoLogin();
    }

    //console.log("============================================= LoginWP2");
    navigate("/");

  }, [user, tokenFromWordpress, timestamp]);

  return (
    <div className={classes.root}>
      <h2>很抱歉，您沒有使用此服務的權限。</h2>
    </div>
  );
};

export default LoginWp;

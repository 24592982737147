import axios from 'axios';

export const beginClientGet = async (message: string): Promise<string> => {
  const clientGetRegex = /@begin\s*client_get\("([^"]+)"\)\s*@end/g;
  let match;
  let str_response=""
  while ((match = clientGetRegex.exec(message)) != null) {
    const url = match[1];
    try {
      const response = await axios.get(url);
      str_response=response.data
    } catch (error) {
      console.error(`Failed to fetch data from ${url}`, error);
    }
  }
  return str_response;
};

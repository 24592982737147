// ./src/components/WebSocketClient.js
export class WebSocketClient {
  constructor(url, onMessage, onReady) {
    this.url = url;
    this.socket = null;
    this.onMessage = onMessage;
    this.onReady = onReady;
    this.messageQueue = [];
    this.connect();
  }

  connect() {
    this.socket = new WebSocket(this.url);

    this.socket.addEventListener("open", (event) => {
      if (this.onReady) {
        this.onReady();
      }
      this.flushMessageQueue();
    });

    this.socket.addEventListener("message", (event) => {
      if (this.onMessage) {
        this.onMessage(event.data);
      }
    });

    this.socket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
    });
  }

  sendMessage(data) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      this.messageQueue.push(data);
    }
  }

  flushMessageQueue() {
    while (this.messageQueue.length > 0) {
      this.sendMessage(this.messageQueue.shift());
    }
  }

  disconnect() {
    this.socket.close();
  }
}

export default WebSocketClient;
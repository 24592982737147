// ./src/recoil/atoms.ts
import { atom } from 'recoil';

export const jwtTokenState = atom<string | null>({
  key: 'jwtTokenState',
  default: null,
});

export const defaultBotrunState = atom<string>({
  key: 'defaultBotrun',
  default: 'index',
});
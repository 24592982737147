// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
// export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL || "ws://localhost:8000/ws/botrun/chat";

// export const API_BASE_URL = "http://localhost:8000";
// export const WEB_SOCKET_URL = "ws://localhost:8000/ws/botrun/chat";

// export const API_BASE_URL = "http://34.72.166.186";
// export const WEB_SOCKET_URL = "ws://34.72.166.186/ws/botrun/chat";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;

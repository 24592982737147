// src/BotrunUseAudioRecorder.ts

import { useState, useEffect } from "react"
import { useAudioRecorder } from "react-audio-voice-recorder"
import moment from "moment"

export default function BotrunUseAudioRecorder() {
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    recordingTime,
  } = useAudioRecorder()
  const [fileData, setFileData] = useState<File | null>(null)
  const [recordDuration, setRecordDuration] = useState(0)

  useEffect(() => {
    setRecordDuration(recordingTime)
  }, [recordingTime])

  useEffect(() => {
    if (recordingBlob !== undefined) {
      const now = moment().format("YYYY_MM_DD_HH_mm_ss")
      const fileName = `${now}.webm`
      const file = new File([recordingBlob], fileName, { type: "audio/webm" })
      setFileData(file)
      // console.log("recordingBlob exists: ", recordingBlob);
    } else {
      // console.log("recordingBlob does not exist");
    }
  }, [recordingBlob])

  return {
    startRecording,
    stopRecording,
    fileData,
    recordDuration,
  }
}

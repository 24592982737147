// ./src/components/ChatMessage.tsx
import React, { useEffect, useState, useRef } from "react";
import { FileCopy } from "@material-ui/icons";
import { beginClientGet } from "./BeginClientGet";
import { beginClientPost } from "./BeginClientPost";
import { beginCode } from "./BeginCode";
import './ChatMessage.css';
import { useTranslation } from 'react-i18next';

interface ChatMessageProps {
  message: string;
  isUserMessage: boolean;
}

// 定義複雜格式的內文有多個 @begin link, @begin img 參雜純文字使用，定義 某區塊內容部分的類型
interface Part {
  type: 'text' | 'html' | 'code';
  content: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  isUserMessage,
}) => {
  const { t } = useTranslation();

  // 2023-12-08 bigcookie: 舊版拿來比對 begin code 的 regular expression
  // const regexForCode = /@begin code[\s\S]*?@end\n\n/g;

  const [clientPostResponse, setClientPostResponse] = useState<string>("");
  const [clientGetResponse, setClientGetResponse] = useState<string>("");
  const { codePath, codeContent } = isUserMessage
    ? { codePath: "", codeContent: "" }
    : beginCode(message);

  const prevClientGets = useRef<any[]>([]);
  const prevClientPosts = useRef<any[]>([]);
  const [formattedMessageParts, setFormattedMessageParts] = useState<Part[]>([]); //顯示複雜格式的內文有多個 @begin link, @begin img 使用的暫存變數。

  // 2023-10-03 bigcookie: begin code 的部份加入透過環境變數設定要顯示、隱藏或是有切換按鈕
  const codeDisplayType = process.env.REACT_APP_CODE_DISPLAY_TYPE || 'show';
  const [isCodeVisible, setCodeVisible] = useState(false);
  const toggleCodeVisibility = () => {
    setCodeVisible(!isCodeVisible);
  };

  const [isPressed, setIsPressed] = useState(false); //複製按鈕用的顏色判斷
  const codeToShow = isCodeVisible ? (
    <div className="code-style" style={{ position: 'relative' }}>
        {/* 右上角的小小複製按鈕 */}
        <button onClick={() => copyToClipboard(codeContent)} 
          onMouseDown={() => setIsPressed(true)} 
          onMouseUp={() => setIsPressed(false)} 
          onMouseLeave={() => setIsPressed(false)} 
          style={{ position: 'absolute', right: 0, top: 0, transformOrigin: 'top right', transform: 'scale(0.7)', border: 'none', backgroundColor: isPressed ? '#808080' : '#f0f0e6', }}>
          <FileCopy />
        </button>


      {codeContent}
    </div>
  ) : (<br />);

  useEffect(() => {
    if (message.includes("@begin client_get") && !isUserMessage) {
      const fetchDataGet = async () => {
        let str_response = await beginClientGet(message);
        if (str_response) {
          setClientGetResponse(str_response);
        }
      };
      fetchDataGet();
    }
    if (message.includes("@begin client_post") && !isUserMessage) {
      const fetchDataPost = async () => {
        let str_response = await beginClientPost(message);
        if (str_response) {
          setClientPostResponse(str_response);
        }
      };
      fetchDataPost();
    }

    // 處理內文包含多個 @begin link 與多個 @begin img 的顯示方式
    if (!isUserMessage) {
      //const linkRegex = /@begin\s*link\("([^"]+)"\)\s*((?:.|\s)*?)\s*@end/g;
      //const imgRegex = /@begin\s*img\("([^"]+)"\)\s*@end/g;
      //整合成一個 Regex，會解析 「@begin link() @end」 以及 「@begin img() @end」 
      const combinedRegex = /@begin\s*(link|img|code)\("([^"]+)"\)\s*([^@]*?)\s*@end/g;
      //const combinedRegex = /@begin\s*(link|img|code)\("([^"]+)"\)\s*([^@]*?)\s*@end(?:\n|$)/g;

      let parts: Part[] = [];
      let lastIndex = 0;

      // 解析並替換自定義標籤
      const parseCustomTags = (regex: RegExp, text: string) => {
        text.replace(regex, (match, type, url, textContent, offset) => {
          // 添加匹配之前的文本
          if (offset > lastIndex) {
            parts.push({ type: 'text', content: text.substring(lastIndex, offset) });
          }

          // 根據類型添加 HTML 元素
          if (type === 'link') {
            parts.push({
              type: 'html', content: ` <a href="${url}" target="_blank" rel="noopener noreferrer"
              style="color: #57a8ff">${textContent}</a> `
            });
          } else if (type === 'img') {
            parts.push({ type: 'html', content: `<br/><img src="${url}" alt="" /><br/>` });
          } else if (type === 'code') {
            parts.push({ type: 'code', content: `` });
          }

          lastIndex = offset + match.length;
          return ''; // 必須返回一個字符串
        });

        // 添加最後一段文本
        if (lastIndex < message.length) {
          parts.push({ type: 'text', content: message.substring(lastIndex) });
        }
      };

      parseCustomTags(combinedRegex, message);
      setFormattedMessageParts(parts);
      console.log("[ChatMessage.tsx] useEffect / parts:", parts);
    }
  }, [message]);

  function copyToClipboard(text:string) {
    navigator.clipboard.writeText(text).then(() => {
      // 複製成功後的回饋操作，比如顯示消息提示
      console.log('Code copied to clipboard!');
    }).catch(err => {
      // 複製失敗的處理，比如顯示錯誤提示
      console.error('Failed to copy: ', err);
    });
  }

  return (
    <div
      style={{
        padding: 8,
        display: "flex",
        justifyContent: isUserMessage ? "flex-end" : "flex-start",
      }}
    >
      <div
        style={{
          backgroundColor: isUserMessage
            ? "#4c6f50"
            : "rgba(255, 255, 255, 0.1)",
          borderRadius: 15,
          padding: "8px 12px",
          maxWidth: "70%",
          wordBreak: "break-word",
          color: isUserMessage ? "white" : "inherit",
          fontSize: "1.1rem",
        }}
      >
        {/* Render Messages with multiple links and imgs Responses */}
        <div>
          {isUserMessage ? (
            // 顯示原始文本
            <pre style={{ margin: 0, lineHeight: "1.6rem", whiteSpace: "pre-wrap" }}>
              <code>{message}</code>
            </pre>            
          ) : (
            formattedMessageParts.map((part, index) => {
              if (part.type === 'html') {
                return <code key={index} dangerouslySetInnerHTML={{ __html: part.content }}></code>;
              } else if (part.type === 'code') {
                // 假設您想以不同的方式呈現 'code' 類型的內容
                return (
                  <pre style={{ margin: 0, lineHeight: "1.6rem", whiteSpace: "pre-wrap" }}>
                    {codeContent && (codeDisplayType == "toggle") && (
                      <span>
                        <button onClick={toggleCodeVisibility}>
                          {isCodeVisible ? t('Hide Source Code') : t('Show Source Code')}
                        </button>

                        {codeToShow}


                      </span>
                    )}
                  </pre>
                );
              } else {
                return (
                  <pre key={index} style={{ margin: 0, lineHeight: '1.6rem', whiteSpace: 'pre-wrap' }}>
                    <code>{part.content}</code>
                  </pre>
                );
              }
            })
          )}

        </div>

        {/* Render HTTP GET Response */}
        {clientGetResponse && (
          <pre style={{ margin: 0, padding: 0 }}>{clientGetResponse}</pre>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;

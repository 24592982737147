// ./src/index.tsx
import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import './i18n'; // 導入 i18n 配置
import App from "./App";
import Login from "./components/Login";
import LoginWp from "./components/LoginWp";
import Welcome from "./components/Welcome";
import NoAuth from "./components/NoAuth";
import { tokenVerify } from "./services/apiService";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { RecoilRoot, useRecoilState } from "recoil";
import { jwtTokenState, defaultBotrunState } from "./recoil/atoms";

const container = document.getElementById("root");
const root = createRoot(container!);
const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const Main = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  const [default_botrun, setDefaultBotrun] = useRecoilState(defaultBotrunState);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // 獲取查詢參數並檢查 botrun_token
  const params = new URLSearchParams(location.search);
  const botrun_token = params.get("botrun_token");
  const useSSO = process.env.REACT_APP_USE_SSO === "True";

  if (params.get("default_botrun") !== null) {
    setDefaultBotrun(params.get("default_botrun") || "index");
  }

  const checkBotrunTokenValidity = async (botrunToken: string) => {
    try {
      const data = await tokenVerify(botrunToken);
      if (data.is_success) {
        setJwtToken(botrunToken);
        setUsername(data.username);
      } else {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const verifyToken = async (token: string) => {
    // 在這裡添加您的 token 驗證邏輯
    // 假設 checkTokenValidity 是一個驗證 token 的 function
    const isValid = await checkBotrunTokenValidity(token);

    if (isValid) {
      setIsLoggedIn(true);
      navigate("/");
    } else {
      // 處理無效的 token
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate("/");
  };

  if (botrun_token) {
    verifyToken(botrun_token);
  }

  return (
    <Routes>
      {useSSO ? (
        <>
          <Route path="/NoAuth" element={isLoggedIn ? <App /> : <NoAuth />} />
          <Route path="/" element={isLoggedIn ? <App /> : <NoAuth />} />
        </>
      ) : (
        <>
          <Route path="/LoginWp" element={<LoginWp onLogin={handleLogin} />} />
          <Route path="/login-admin" element={isLoggedIn ? <App /> : <Login onLogin={handleLogin} />} />
          <Route path="/" element={isLoggedIn ? <App /> : <NoAuth />} />

        </>
      )}
    </Routes>
  );
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <Main />
        </Router>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);
